export const react_domain = `${window.location.origin}`;
// export const react_domain = `https://software.one-algo.com`;



export const smartAlogUrl = `https://newpenal.pandpinfotech.com/backend/`;
// export const smartAlogUrl = "http://localhost:7700/";


export const base_url = `${window.location.origin}/backend/`
// export const base_url = "https://trade.pandpinfotech.com/backend/";
// export const base_url = "http://localhost:7700/";
// export const base_url = "http://185.209.75.22:7700/";
// export const base_url = "https://newpenal.pandpinfotech.com/backend/"
// export const base_url = "http://185.209.75.22:7700/";
// export const base_url = "https://trade.codingpandit.com/backend/";
// export const base_url = "https://software.oneplanetitsolution.com/backend/";
// export const base_url = "https://software.algobullstradingsolutions.com/backend/";
// export const base_url = "https://software.finnshri.com/backend/";
// export const base_url = "https://software.corebizinfotech.com/backend/";
// export const base_url = "https://software.growskyinfotech.com/backend/";
// export const base_url = "https://software.sumedhainn.com/backend/";
// export const base_url = "https://software.growupalgo.com/backend/";
// export const base_url = "https://software.fincodify.com/backend/";
// export const base_url = "https://software.uniquetechnology.in/backend/";
// export const base_url = "https://software.unitythesmartalgo.com/backend/";
// export const base_url = "https://software.tradeonn.com/backend/";
// export const base_url = "https://software.one-algo.com/backend/";





